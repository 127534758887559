import Link from 'next/link'
import styles from './NavItem.module.scss'
import { FC, useState } from 'react'
import ArrowSvg from '../../public/icons/arrow-nav.svg'
import DropdownMenu from './DropdownMenu'
import cn from 'classnames'
import LightningSvg from '../../public/icons/lightning.svg'
import { INavItem } from '../../types'

interface NavItemProps {
  item: INavItem
  hasCount: boolean
  mobile?: boolean
  footer?: boolean
  isLast?: boolean
}

const NavItem: FC<NavItemProps> = ({
  item,
  hasCount,
  mobile,
  footer,
  isLast,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const renderNavItemContent = () => (
    <>
      <span className={styles.name}>{item.name}</span>
      {hasCount && !footer && <sup className={styles.total}>{item.total}</sup>}
      {item.subcat && !mobile && !footer && (
        <ArrowSvg className={styles.arrow} />
      )}
      {item.slug === 'freedurov' && <LightningSvg />}
    </>
  )

  return (
    <div
      className={cn(
        styles.container,
        mobile && styles.mobile,
        footer && styles.footer
      )}
      onMouseEnter={() => setIsDropdownVisible(true)}
      onMouseLeave={() => setIsDropdownVisible(false)}
    >
      {item.href ? (
        <Link
          href={item.href}
          className={cn(
            styles.item,
            item.slug === 'freedurov' && styles.freedurov
          )}
          key={item.name}
        >
          {renderNavItemContent()}
        </Link>
      ) : (
        <div className={styles.item} key={item.name}>
          {renderNavItemContent()}
        </div>
      )}
      {isDropdownVisible && item.subcat && !mobile && !footer && (
        <DropdownMenu subItems={item.subcat} isLast={isLast} />
      )}
    </div>
  )
}

export default NavItem
