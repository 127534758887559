import { FC } from 'react'

import styles from './Rate.module.scss'
import Arrow from '../../public/icons/arrow.svg'

function formatNumberWithSpaces(number) {
  // Convert the number to a string and split into integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split('.')

  // Apply the formatting to the integer part
  const reversedIntegerPart = integerPart.split('').reverse().join('')
  const stringWithSpaces = reversedIntegerPart.match(/.{1,3}/g).join(' ')
  const formattedIntegerPart = stringWithSpaces.split('').reverse().join('')

  // Return the formatted number, including the decimal part if it exists
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart
}

interface IRateProps {
  symbol: string
  current_price: number
  price_change_24h: number
  price_change_percentage_24h: number
}
const Rate: FC<IRateProps> = ({
  symbol,
  current_price,
  price_change_24h,
  price_change_percentage_24h,
}) => {
  const up = price_change_24h >= 0
  const price = formatNumberWithSpaces(current_price.toFixed(2))
  const priceChange = formatNumberWithSpaces(price_change_24h.toFixed(2))

  return (
    <p className={styles.rate}>
      <span className={styles.symbol}>{symbol} = </span>
      <span>
        {price}
        {symbol === 'usd' || symbol === 'eur' ? '₽' : '$'}{' '}
      </span>

      <span className={up ? styles.up : styles.down}>
        <Arrow />{' '}
        <span>
          {priceChange} ({price_change_percentage_24h.toFixed(2)} %)
        </span>
      </span>
    </p>
  )
}

export default Rate
