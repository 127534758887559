import React, { FC, createContext, useEffect, useState } from 'react'
import { IUserData } from '../features/company/types'
import useLocalStorage from '../hooks/useLocalStorage'
import { defaultUser } from '../utils/getTelegramUserData'
import initUser from '../features/common/UserCard/initUser'

interface IAuthContext {
  userData: IUserData
  setUserData: React.Dispatch<React.SetStateAction<IUserData>>
  auth: () => void
  needAuth: () => boolean
  loaded: boolean
}

export const AuthContext = createContext({} as IAuthContext)

const AuthProvider: FC = ({ children }) => {
  const [userData, setUserData] = useLocalStorage('telegramUser', defaultUser)
  const [loaded, setLoaded] = useState(false)

  function needAuth() {
    return (
      !userData.authTime ||
      Date.parse(Date()) - Date.parse(userData.authTime) > 86400 * 1000
    )
  }

  async function auth() {
    if (typeof window === 'undefined') return
    window.Telegram.Login.auth(
      { bot_id: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID },
      (user) => {
        if (user) {
          setUserData({ ...user, authTime: new Date() })
        }
      }
    )
  }

  useEffect(() => {
    initUser()
  }, [])

  useEffect(() => {
    if (needAuth()) {
      setUserData(defaultUser)
    }
  }, [])

  useEffect(() => {
    if (userData) {
      setLoaded(true)
    }
  }, [userData])

  return (
    <AuthContext.Provider
      value={{ userData, setUserData, auth, needAuth, loaded }}
    >
      {children}
    </AuthContext.Provider>
  )
}
export default AuthProvider
