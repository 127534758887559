import Link from 'next/link'
import styles from './DropdownMenu.module.scss'
import { FC } from 'react'
import cn from 'classnames'
import { INavItem } from '../../types'

interface DropdownMenuProps {
  subItems: INavItem['subcat']
  isLast: boolean
}

const DropdownMenu: FC<DropdownMenuProps> = ({ subItems, isLast }) => {
  if (!subItems) return null

  return (
    <div className={cn(styles.dropdown, isLast && styles.isLast)}>
      {subItems.map((subItem) => (
        <Link
          className={styles.dropdownItem}
          key={subItem.name}
          href={subItem.href}
        >
          {subItem.name}
          {subItem.total && <sup className={styles.total}>{subItem.total}</sup>}
        </Link>
      ))}
    </div>
  )
}

export default DropdownMenu
