import { Socnet } from '../interfaces'

export const getSocName = (code: Socnet): string => {
  if (!code) {
    return null
  }

  let name = ''

  switch (code) {
    case 'fb':
      name = 'Facebook'
      break
    case 'tg':
      name = 'Telegram'
      break
    case 'tw':
      name = 'Twitter'
      break
    case 'vk':
      name = 'VK'
      break
    case 'ya':
      name = 'Яндекс.Дзен'
      break
    default:
      break
  }

  return name
}
