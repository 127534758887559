import React, { FC } from 'react'
import cn from 'classnames'
import styles from './MaxWidth.module.scss'

type MaxWidthSize = 'small' | 'medium'

interface MaxWidthInterface {
  className?: string
  size?: MaxWidthSize
  mobileFullWidth?: boolean
  work?: boolean
}

export const MaxWidth: FC<MaxWidthInterface> = ({
  children,
  className,
  work,
  size,
}) => {
  return (
    <div
      className={cn(
        styles.maxWidth,
        work && styles.work,
        styles[size],
        className
      )}
    >
      {children}
    </div>
  )
}

export default MaxWidth
