const defaultUser = {
  first_name: '',
  last_name: '',
  photo_url: '',
  authTime: '',
  username: '',
  auth_date: 0,
  hash: '',
  id: 0,
}

function getTelegramUserData() {
  try {
    const userData = window.localStorage.getItem('telegramUser')
    return userData ? JSON.parse(userData) : defaultUser
  } catch (error) {
    console.warn(`Error reading localStorage key “telegramUser”:`, error)
    return defaultUser
  }
}

export { getTelegramUserData, defaultUser }
