import { FC } from 'react'
import Link from 'next/link'

import styles from './Aside.module.scss'

import AsideLogo from '../../../public/icons/aside-logo.svg'
import Search from '../../../public/icons/search.svg'
import { getSocName } from '../../../utils/getSocName'
import { ISocial, Socnet } from '../../../interfaces'

interface IPropsAside {
  socials: ISocial[]
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>
}

const Aside: FC<IPropsAside> = ({ socials, children, setShowSearchBar }) => {
  const handlerShowSearchBar = () => {
    setShowSearchBar((prev) => !prev)
  }

  return (
    <aside className={styles.aside}>
      <button
        type="button"
        className={styles.search}
        onClick={handlerShowSearchBar}
        aria-label="Поиск"
      >
        <Search />
      </button>

      <ul className={styles.soc}>
        {socials.map((s) => (
          <li className={styles.socItem} key={s.code}>
            <Link
              className={styles.socLink}
              href={s.href}
              target="_blank"
              title={getSocName(s.code as Socnet)}
              aria-label={`Ссылка на ${s.label}`}
            >
              {s.icon}
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.content}>
        <div className={styles.content__inner}>{children}</div>
        <Link href="/" className={styles.asideLogoLink} title="Go to main page">
          <AsideLogo className={styles.asideLogo} />
        </Link>
      </div>
    </aside>
  )
}

export default Aside
