import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { FC, memo } from 'react'
import { ISEO } from '../../interfaces/Seo'
import useLocalStorage from '../../hooks/useLocalStorage'
import { ThemeEnum } from '../../types'

interface IMeta extends ISEO {
  rssLink?: string
  hasPagination?: boolean
  ogSize?: {
    width: number
    height: number
  }
}

const Meta: FC<IMeta> = ({
  title,
  metaTitle,
  ogTitle,
  description,
  metaDescription,
  ogDescription,
  ogImage,
  coverImage,
  publishedAt,
  type,
  profileName,
  rssLink,
  hasPagination,
  ogSize,
}) => {
  const router = useRouter()
  const [storageTheme] = useLocalStorage<ThemeEnum>('theme', ThemeEnum.system)
  let currentURL = new URL(router.asPath, process.env.NEXT_PUBLIC_HOST)
  let link = currentURL.origin + currentURL.pathname
  let currentDescription = metaDescription || description

  if (hasPagination) {
    const arrayFromLink = link.split('/')
    const page = Number(arrayFromLink[arrayFromLink.length - 1])

    if (page) {
      arrayFromLink.pop()
      currentDescription = `${currentDescription} - Страница ${page}`
    }

    link = arrayFromLink.join('/')
  }

  function getFormat(img: string): string {
    return img.split('.').pop()
  }

  return (
    <Head>
      <meta charSet="utf-8" />
      <link
        rel="preload"
        href="/fonts/Inter-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Inter-Medium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Inter-SemiBold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Inter-Bold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link rel="icon" type="image/svg" href="/icons/favicon-icon.svg" />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/logo-maskable-192x192.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="512x512"
        href="/icons/logo-maskable-512x512.png"
      />
      <link rel="apple-touch-icon" sizes="57x57" href="/favicon/57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/favicon/60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/favicon/72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/favicon/76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon/114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon/120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon/144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon/152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon/192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/16x16.png"
      />
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, maximum-scale=1"
      />

      <link rel="manifest" href="/manifest.json" />
      <meta name="robots" content="max-image-preview:large" />

      {rssLink && !rssLink.includes('author') && (
        <link rel="alternate" type="application/rss+xml" href={rssLink} />
      )}

      <title>{title}</title>
      {metaTitle && <meta key="title" name="title" content={metaTitle} />}

      {currentDescription && (
        <meta
          key="description"
          name="description"
          content={currentDescription}
        />
      )}

      {type && <meta key="og:type" property="og:type" content={type} />}

      <meta property="og:site_name" content="Код Дурова" />
      {(ogTitle || metaTitle || title) && (
        <meta
          key="og:title"
          property="og:title"
          content={ogTitle || metaTitle || title}
        />
      )}

      {(ogDescription || currentDescription) && (
        <meta
          key="og:description"
          property="og:description"
          content={ogDescription || currentDescription}
        />
      )}

      {publishedAt && (
        <meta property="article:published_time" content={publishedAt} />
      )}

      {profileName && (
        <meta property="og:profile:username" content={profileName} />
      )}

      <meta key="og:url" property="og:url" content={link} />

      {(ogImage || coverImage) && (
        <meta
          key="og:image"
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_IMAGE_OPTIM}/rs:fill/w:${
            ogSize?.width || 1920
          }/q:85/plain/${encodeURIComponent(ogImage || coverImage)}`}
        />
      )}
      {(ogImage || coverImage) && (
        <meta
          key="og:image:secure_url"
          property="og:image:secure_url"
          content={`${process.env.NEXT_PUBLIC_IMAGE_OPTIM}/rs:fill/w:${
            ogSize?.width || 1920
          }/q:85/plain/${encodeURIComponent(ogImage || coverImage)}`}
        />
      )}

      {(ogImage || coverImage) && (ogDescription || currentDescription) && (
        <meta
          property="og:image:alt"
          content={ogDescription || currentDescription}
        />
      )}

      {type === 'article' && coverImage && (
        <>
          <meta
            key="twitter:card"
            name="twitter:card"
            content="summary_large_image"
          />
          <meta
            key="twitter:image"
            property="twitter:image"
            content={`${
              process.env.NEXT_PUBLIC_IMAGE_OPTIM
            }/rs:fill/w:1920/q:85/plain/${encodeURIComponent(coverImage)}`}
          />
        </>
      )}
      {(ogImage || coverImage) && (
        <meta
          property="og:image:type"
          content={`image/${getFormat(ogImage || coverImage)}`}
        />
      )}

      <meta
        key="og:image:width"
        property="og:image:width"
        content={ogSize?.width.toString() || '1920'}
      />
      <meta
        key="og:image:height"
        property="og:image:height"
        content={ogSize?.height.toString() || '1080'}
      />

      <meta
        name="zen-verification"
        content="GNqFL9XmD5CIHggNlJw5AFNvUVYIUxnR7tLVMXb5RRjQOGl3Kvv21mBLQezWuFFQ"
      />

      <link rel="canonical" href={link} />

      <link rel="alternate" href="https://kod.ru/" hrefLang="ru-ru" />
      <meta
        name="theme-color"
        content={storageTheme === ThemeEnum.dark ? '161c26' : 'ffffff'}
      />
    </Head>
  )
}

export default memo(Meta)
