import React, { FC } from 'react'
import styles from './BodyHeader.module.scss'
import Rates from './Rates'
import Link from 'next/link'
import HeaderLogoSvg from '../../public/icons/logo-header.svg'
import Forum from '../../public/icons/forum-icon.svg'
import { LayoutAuth } from './LayoutAuth/LayoutAuth'
import cn from 'classnames'
import GigaChat from '../common/GigaChat'

interface IPropsBody {
  posY: number
}

export const BodyHeader: FC<IPropsBody> = ({ posY }) => {
  return (
    <div className={cn(styles.middle, posY > 80 && styles.withoutLine)}>
      <div className={styles.rates}>
        <Rates />
      </div>
      <Link className={styles.logo} href="/" aria-label="На главную">
        <HeaderLogoSvg />
      </Link>
      <div className={styles.buttons}>
        {/* <GigaChat className={styles.chat} /> */}
        <Link
          href="https://t.me/forum_code"
          className={styles.forum}
          target="_blank"
        >
          Форум
          <Forum />
        </Link>
        <LayoutAuth className={styles.auth} />
      </div>
    </div>
  )
}
