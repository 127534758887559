import React, { ReactNode, useEffect, useState } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import Vk from '../../public/icons/vk.svg'
import Tg from '../../public/icons/tg.svg'
import Tw from '../../public/icons/tw.svg'
import Yt from '../../public/icons/yt.svg'
import Ya from '../../public/icons/dz.svg'
import Meta from './Meta'
import { ISEO } from '../../interfaces/Seo'
import styles from './Layout.module.scss'
import Header from './Header'
import DocSvg from '../../public/icons/doc.svg'
import Aside from './Aside/Aside'
import { MISTAKE_BOT_LINK } from '../../lib/constants/links'
import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('./Footer'))

type Props = {
  children?: ReactNode
  meta: ISEO
  footerMargin?: boolean
  rssLink?: string
}

const Layout = ({ children, meta, footerMargin, rssLink }: Props) => {
  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    function handleErrorReport(e: KeyboardEvent) {
      if (e.ctrlKey && e.key.toLowerCase() === 'enter') {
        window.open(MISTAKE_BOT_LINK, '_blank').focus()
      }
    }

    window.addEventListener('keyup', handleErrorReport)

    return () => {
      window.removeEventListener('keyup', handleErrorReport)
    }
  }, [])

  const router = useRouter()

  const SOCIALS = [
    {
      code: 'tg',
      href: 'https://t.me/d_code',
      icon: <Tg />,
      label: 'telegram',
    },
    {
      code: 'vk',
      href: 'https://vk.com/kod',
      icon: <Vk />,
      label: 'vk',
    },
    {
      code: 'yt',
      href: 'https://www.youtube.com/@kod_durovaa',
      icon: <Yt />,
      label: 'youtube',
    },
    {
      code: 'tw',
      href: 'https://twitter.com/durovcode',
      icon: <Tw />,
      label: 'twitter',
    },
    {
      code: 'ya',
      href: 'https://dzen.ru/kod',
      icon: <Ya />,
      label: 'dzen',
    },
  ]

  const isError = router.pathname === '/404' || router.pathname === '/500'

  return (
    <div className={styles.container}>
      <Meta {...meta} rssLink={rssLink} />
      <Header
        socials={SOCIALS}
        showSearchBar={showSearchBar}
        setShowSearchBar={setShowSearchBar}
      />
      <div className={styles.layout}>
        <Aside socials={SOCIALS} setShowSearchBar={setShowSearchBar}>
          {/* mock */}
          <div className={styles.mock}>
            <p>Специальные выпуски издания «Код Дурова»</p>
            <DocSvg />
          </div>
          {/* end mock */}
        </Aside>
        <div className={isError ? styles.errorWrapper : undefined}>
          <div
            className={cn(
              styles.children,
              footerMargin === undefined || footerMargin
                ? styles.footerMargin
                : undefined
            )}
          >
            {children}
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <footer className={styles.footer}>
          <Footer socials={SOCIALS} />
        </footer>
      </div>
    </div>
  )
}

export default Layout
