import { FC } from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import PreviewItem from '../../interfaces/PreivewItem'
import Image from '../common/Image'
import styles from './Line.module.scss'
import Link from 'next/link'
import { getUtcDate } from '../../utils/dates'
import { useGetPostsQuery } from '../../queries/generated/blog/schema'
import { BLOG_CLIENT } from '../../lib/constants/index'

interface ILine {
  mobile?: boolean
  className?: string
}

const Line: FC<ILine> = ({ mobile, className }) => {
  const { data: postsData } = useGetPostsQuery({
    context: BLOG_CLIENT,
    variables: {
      take: 6,
    },
  })

  if (!postsData) {
    return null
  }

  function renderPost(post: PreviewItem) {
    return (
      <li key={post.id}>
        <Link href={`/${post.slug}`}>
          {!mobile && post.picture && (
            <Image
              className={styles.lineImage}
              src={post.picture.url}
              width={30}
              height={30}
              alt={post.title}
              loader={({ src, width }) => {
                return `${
                  process.env.NEXT_PUBLIC_IMAGE_OPTIM
                }/pr:sharp/rs:fill/w:${width}/mw:30/mh:30/q:${75}/plain/${encodeURIComponent(
                  src
                )}@webp`
              }}
              skipSizes
            />
          )}
          <p className={cn('h5', styles.time)}>
            {dayjs(post.published_at).format('HH:mm')}
          </p>
          <h5 className={styles.title}>{post.title}</h5>
        </Link>
      </li>
    )
  }

  return (
    <div className={cn(styles.lineWrapper, className)}>
      <div className={styles.line}>
        <ul className={styles.lineList}>
          {postsData?.posts?.map?.(renderPost)}
        </ul>
        <ul className={styles.lineList}>
          {postsData?.posts?.map?.(renderPost)}
        </ul>
      </div>
    </div>
  )
}

export default Line
