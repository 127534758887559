import { FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import styles from './Rates.module.scss'
import Rate from './Rate'
import { useCoinsQuery } from '../../queries/generated/blog/schema'
import { BLOG_CLIENT } from '../../lib/constants'

const Rates: FC = () => {
  const { data: rates } = useCoinsQuery({
    context: BLOG_CLIENT,
  })

  dayjs.locale('ru')
  const currentDate = dayjs().format('D MMMM YYYY')

  if (!rates) {
    return null
  }

  return (
    <div className={styles.rates}>
      <div className={styles.date}>{currentDate}</div>
      <Swiper
        className={styles.slider}
        modules={[Autoplay]}
        loop
        direction="vertical"
        autoplay={{
          delay: 6000,
        }}
      >
        {rates.coins?.map?.((rate) => (
          <SwiperSlide className={styles.slide} key={rate.id}>
            <Rate
              symbol={rate.symbol}
              current_price={rate.current_price}
              price_change_24h={rate.price_change_24h}
              price_change_percentage_24h={rate.price_change_percentage_24h}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Rates
