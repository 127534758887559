import { FC } from 'react'
import Link from 'next/link'

import styles from './MobileTop.module.scss'
import SearchIcon from '../../public/icons/search.svg'
import Logo from './icons/header-mobile-logo.svg'
import Burger from '../../public/icons/burger.svg'
import CloseSvg from '../../public/icons/close.svg'
import MaxWidth from './MaxWidth'

interface IMobileTopProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const MobileTop: FC<IMobileTopProps> = ({
  open,
  setOpen,
  setShowSearchBar,
}) => {
  const handlerShowSearchBar = () => {
    setShowSearchBar((prev) => !prev)
  }

  return (
    <div className={styles.wrapper}>
      <MaxWidth className={styles.row}>
        <button
          className={styles.search}
          type="button"
          onClick={handlerShowSearchBar}
          aria-label="Поиск"
        >
          <SearchIcon />
        </button>
        <Link className={styles.logo} href="/" aria-label="На главную">
          <Logo />
        </Link>
        <button
          className={styles.burger}
          type="button"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          aria-label={open ? 'Закрыть меню' : 'Открыть меню'}
        >
          {open ? <CloseSvg /> : <Burger />}
        </button>
      </MaxWidth>
    </div>
  )
}

export default MobileTop
