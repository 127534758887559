import React, { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'
import styles from './Header.module.scss'
import Nav from './Nav'
import MobileTop from './MobileTop'
import ModalFooter from './ModalFooter'
import { SearchBarHeader } from './SearchBarHeader'
import Line from './Line'
import { ISocial } from '../../interfaces'
import { NAV_ITEMS } from '../../lib/constants/links'
import Modal from './Modal'
import GigaChat from '../common/GigaChat'
import { BodyHeader } from './BodyHeader'
import useWindowSize from '../../hooks/useWindowSize'

interface IPropsHeader {
  socials: ISocial[]
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>
  showSearchBar: boolean
}

const Header: FC<IPropsHeader> = ({
  socials,
  setShowSearchBar,
  showSearchBar,
}) => {
  const [open, setOpen] = useState(false)
  const [closing, setClosing] = useState(false)
  const [posY, setPosY] = useState(0)
  const { width } = useWindowSize()
  const isMobile = width <= 992

  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose)
    router.events.on('routeChangeError', handleClose)
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        handleClose()
      }
      return true
    })

    return () => {
      router.events.off('routeChangeComplete', handleClose)
      router.events.off('routeChangeError', handleClose)
      router.beforePopState(() => true)
    }
  }, [router])

  function handleClose() {
    setClosing(true)
    setTimeout(() => {
      setClosing(false)
      setOpen(false)
    }, 200)
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentPosY = window.scrollY
      setPosY((prevPosY) => {
        if (
          (currentPosY > 80 && prevPosY <= 80) ||
          (currentPosY < 20 && prevPosY >= 20)
        ) {
          return currentPosY
        }

        return prevPosY
      })
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <div className={styles.searchBarWrapper}>
        <SearchBarHeader
          setShowSearchBar={setShowSearchBar}
          showSearchBar={showSearchBar}
        />
      </div>
      <div className={styles.top}>
        <Line className={styles.line} />
      </div>
      <div className={cn(styles.fixed, open && styles['fixed--opened'])}>
        <header className={cn(styles.header, posY > 80 && styles.withoutLine)}>
          <div className={styles.desktop}>
            <BodyHeader posY={posY} />
            <Nav navItems={NAV_ITEMS} />
          </div>
          <MobileTop
            open={open}
            setOpen={setOpen}
            setShowSearchBar={setShowSearchBar}
          />
          {/* {isMobile && <GigaChat isMobile />} */}
        </header>

        {open && (
          <Modal
            open={open}
            handleClose={handleClose}
            closing={closing}
            className={styles.root}
            blockBody={open}
          >
            <ModalFooter
              socials={socials}
              modal
              open={open}
              setOpen={setOpen}
            />
          </Modal>
        )}
      </div>
      <div className={styles.bottom}>
        <Line className={styles.line} />
      </div>
    </>
  )
}

export default Header
