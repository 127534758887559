import React, { FC, MouseEventHandler, useEffect, useRef } from 'react'

import styles from './Modal.module.scss'
import cn from 'classnames'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from 'body-scroll-lock-upgrade'

interface IPropsModal {
  open: boolean
  handleClose: () => void
  closing?: boolean
  className?: string
  onMouseLeave?: MouseEventHandler<HTMLDialogElement>
  blockBody?: boolean
}

const Modal: FC<IPropsModal> = ({
  open,
  handleClose,
  closing,
  children,
  className,
  onMouseLeave,
  blockBody,
}) => {
  const modalRef = useRef()

  useEffect(() => {
    if (blockBody === undefined) return

    if (blockBody) {
      disableBodyScroll(modalRef.current)
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [blockBody])

  return (
    <dialog
      open={open}
      ref={modalRef}
      onClose={handleClose}
      className={cn(
        styles.root,
        closing ? styles.rootClosing : undefined,
        className
      )}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </dialog>
  )
}

export default Modal
