import { FC } from 'react'
import cn from 'classnames'

import styles from './Nav.module.scss'
import { INavItem, INavItemMobile, NAV_ITEMS } from '../../lib/constants/links'
import NavItem from './NavItem'
import {
  useGetCountPostsQuery,
  useMainPageInfoQuery,
} from '../../queries/generated/blog/schema'
import { BLOG_CLIENT } from '../../lib/constants'

interface INavProps {
  className?: string
  negative?: boolean
  mobile?: boolean
  footer?: boolean
  navItems: INavItemMobile[] | INavItem[]
}

export interface INavItemWithCount extends INavItem {
  total?: number
}

interface TagData {
  countPosts: number
  slug: string
  id: string
}

function isNavItemWithCount(
  item: INavItemWithCount | INavItem
): item is INavItemWithCount {
  if (!item) return
  return (item as INavItemWithCount).total !== undefined
}
const Nav: FC<INavProps> = ({
  className,
  negative = false,
  mobile,
  footer,
  navItems,
}) => {
  const { data: data } = useGetCountPostsQuery({
    context: BLOG_CLIENT,
  })

  const updateNavItems = (navItems: INavItem[], data: TagData): INavItem[] => {
    if (!data) return navItems
    return navItems.map((navItem) => {
      if (navItem.slug === 'freedurov') {
        return navItem
      }

      if (navItem.subcat) {
        const updatedSubcat = updateNavItems(navItem.subcat, data)

        return { ...navItem, subcat: updatedSubcat, total: null }
      } else {
        const matchingDataKey = Object.keys(data).find((key) => {
          const tag = data[key]

          return tag.slug === navItem.slug && tag.countPosts
        })

        if (matchingDataKey) {
          const matchingData = data[matchingDataKey]

          return { ...navItem, total: matchingData.countPosts }
        }
        return navItem
      }
    })
  }

  return (
    <nav
      className={cn(
        styles.nav,
        className,
        negative && styles.negative,
        mobile && styles.mobile,
        footer && styles.footer
      )}
    >
      {updateNavItems(navItems, data as TagData).map((item, index) => {
        const hasCount = isNavItemWithCount(item)

        return (
          <NavItem
            key={item.name}
            item={item}
            hasCount={hasCount}
            mobile={mobile}
            footer={footer}
            isLast={index === NAV_ITEMS.length - 1}
          />
        )
      })}
    </nav>
  )
}

export default Nav
