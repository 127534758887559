import React, { FC, useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'

import MaxWidth from './MaxWidth'
import styles from './ModalFooter.module.scss'
import MobileTop from './MobileTop'
import Forum from '../../public/icons/forum-icon.svg'
import { LayoutAuth } from './LayoutAuth/LayoutAuth'
import Nav from './Nav'
import { getSocName } from '../../utils/getSocName'
import { ISocial, Socnet } from '../../interfaces'
import SmallLogoSvg from '../../public/icons/small-logo.svg'
import { SearchBarHeader } from './SearchBarHeader'
import { NAV_ITEMS_MOBILE } from '../../lib/constants/links'

interface IModalFooterProps {
  modal?: boolean
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  socials: ISocial[]
}

const ModalFooter: FC<IModalFooterProps> = ({
  setOpen,
  open,
  modal,
  socials,
}) => {
  const [year] = useState(new Date().getFullYear())
  const [showSearchBar, setShowSearchBar] = useState(false)

  return (
    <div className={cn(styles.wrapper, modal && styles.modal)}>
      <MaxWidth className={styles.container}>
        <div className={styles.searchBarWrapper}>
          <SearchBarHeader
            setShowSearchBar={setShowSearchBar}
            showSearchBar={showSearchBar}
          />
        </div>
        <MobileTop
          open={open}
          setOpen={setOpen}
          setShowSearchBar={setShowSearchBar}
        />
        <div className={styles.buttons}>
          <Link
            href="https://t.me/forum_code"
            className={cn(styles.forum, styles.button)}
            target="_blank"
          >
            Форум
            <Forum />
          </Link>
          <LayoutAuth className={cn(styles.auth, styles.button)} />
        </div>
        <p className={styles.tag}>[ Меню ]</p>

        <Nav mobile navItems={NAV_ITEMS_MOBILE} />
        <ul className={styles.soc}>
          {socials.map((s) => (
            <li className={styles.socItem} key={s.code}>
              <Link
                className={styles.socLink}
                href={s.href}
                target="_blank"
                title={getSocName(s.code as Socnet)}
              >
                {s.icon}
              </Link>
            </li>
          ))}
        </ul>
        <SmallLogoSvg />
        <p className={styles.copyright}>
          © {year}, Код Дурова. Все права защищены.
        </p>
      </MaxWidth>
    </div>
  )
}

export default ModalFooter
