import styles from './SearchBarHeader.module.scss'
import SearchIcon from '../../public/icons/search-white.svg'
import CloseIcon from '../../public/icons/close-white.svg'
import cn from 'classnames'
import { IPropsTypeShowBar } from '../../interfaces/ShowSearchBar'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'

export const SearchBarHeader: FC<IPropsTypeShowBar> = ({
  showSearchBar,
  setShowSearchBar,
}) => {
  const router = useRouter()
  const ref = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')

  const handlerShowSearchBar = () => {
    setShowSearchBar((prev) => !prev)
  }

  useEffect(() => {
    if (ref.current && showSearchBar) {
      ref.current.focus()
    }
  }, [showSearchBar])

  return (
    <div
      className={cn(
        styles.searchBar,
        showSearchBar ? styles.showSearchBar : styles.hideSearchBar
      )}
    >
      <div className={styles.searchBar__container}>
        <button
          type="button"
          className={styles.searchBar__buttonSearch}
          title="Search"
          onClick={() => {
            router.push(
              { pathname: '/search', query: { search } },
              { pathname: '/search', query: { search } },
              {
                shallow: true,
              }
            )
            setShowSearchBar(false)
          }}
        >
          <SearchIcon className={styles.searchIcon} />
        </button>
        <div className={styles.searchBar__input}>
          <input
            type="text"
            placeholder="Искать в Коде Дурова"
            className={styles.searchBar__field}
            onChange={(e) => setSearch(e.target.value)}
            ref={ref}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                router.push(
                  { pathname: '/search', query: { search } },
                  { pathname: '/search', query: { search } },
                  {
                    shallow: true,
                  }
                )
                setShowSearchBar(false)
              }
            }}
            value={search}
          />
        </div>
        <button
          type="button"
          onClick={handlerShowSearchBar}
          className={styles.searchBar__button}
          title="Close search bar"
        >
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
    </div>
  )
}
