import React, { useContext, FC, useState, useRef, useMemo } from 'react'
import ExitIcon from '../../../public/icons/exit.svg'
import { AuthContext } from '../../../contexts/AuthContext'
import styles from './LayoutAuth.module.scss'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { defaultUser } from '../../../utils/getTelegramUserData'
import Image from '../../common/Image'
import Link from 'next/link'
import ArrowIcon from '../../../public/icons/arrow-auth.svg'
import { animateAccordion } from '../../../utils/animateAccordion'

interface ILayoutAuth {
  className?: string
}

export const LayoutAuth: FC<ILayoutAuth> = ({ className }) => {
  const { auth, userData, needAuth, setUserData } = useContext(AuthContext)

  const { first_name: name, photo_url: avatar, authTime } = userData

  const router = useRouter()
  let companySlug = ''
  const [open, setOpen] = useState(false)
  const removeOpen = useRef(null)
  const container = useRef(null)
  const content = useRef(null)
  const timestamp = useMemo(() => Date.now(), [avatar])

  const handlerLeave = () => {
    removeOpen.current = setTimeout(() => {
      setOpen(false)
      animateAccordion(container.current, content.current, false)
    }, 100)
  }

  const handlerClick = () => {
    const updateOpen = !open
    setOpen(updateOpen)
    animateAccordion(container.current, content.current, updateOpen)
  }

  const handlerEnter = () => {
    clearTimeout(removeOpen.current)

    if (container.current.classList.contains('accordion__container--hidden')) {
      setOpen(true)
      animateAccordion(container.current, content.current, true)
    }
  }

  if (router.pathname.split('/')[1] === 'company') {
    companySlug = router.query.name as string
  }

  if (needAuth() || !authTime) {
    return (
      <button className={cn('auth', styles.auth, className)} onClick={auth}>
        Войти
        <ExitIcon />
      </button>
    )
  }

  const handlerExit = () => {
    setUserData(defaultUser)
  }

  return (
    <div className={cn(styles.user, className)}>
      <button
        className={styles.avatarBox}
        onClick={handlerClick}
        onMouseLeave={handlerLeave}
        onMouseEnter={handlerEnter}
        type="button"
      >
        <div className={cn(styles.avatar, avatar && styles.withPhoto)}>
          {avatar && (
            <Image
              src={`${avatar}?time=${timestamp}`}
              width={34}
              height={34}
              alt=""
            />
          )}
        </div>
        <p className={styles.name} title={name}>
          {name}
        </p>
        <ArrowIcon className={cn(styles.icon, open && styles.open)} />
      </button>
      <div
        ref={container}
        className={'accordion__container accordion__container--hidden'}
      >
        <div
          ref={content}
          onMouseLeave={handlerLeave}
          onMouseEnter={() => clearTimeout(removeOpen.current)}
          className={styles.card}
        >
          <div className={cn(styles.firstLine, 'sf')}>
            <p className={styles.name} title={name}>
              {name}
            </p>
            <p className={styles.text}>
              Помогите другим пользователям выбрать лучшего работодателя
            </p>
          </div>

          <div className={cn(styles.secondLine, 'sf')}>
            <Link href={'/new-review'} className={styles.link}>
              Оставить отзыв
            </Link>
            <div className={cn(styles.delimeter)}></div>
            <button className={styles.exit} onClick={handlerExit} type="button">
              Выйти
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
