export function animateAccordion(
  currentAccordionContainer: HTMLElement,
  currentAccordionSublist: HTMLElement,
  isExpanding: boolean
) {
  if (isExpanding) {
    currentAccordionContainer.classList.remove('accordion__container--hidden')
  }

  if ('animate' in Element.prototype) {
    const startHeight = isExpanding
      ? '0px'
      : `${currentAccordionSublist.scrollHeight}px`
    const endHeight = isExpanding
      ? `${currentAccordionSublist.scrollHeight}px`
      : '0px'
    const startOpacity = isExpanding ? '0' : '1'
    const endOpacity = isExpanding ? '1' : '0'
    const duration = isExpanding ? 250 : 200
    const easing = isExpanding
      ? 'cubic-bezier(0.215, 0.61, 0.355, 1)'
      : 'cubic-bezier(0.55, 0.085, 0.68, 0.53)'

    const containerAnimation = currentAccordionContainer.animate(
      [
        { height: startHeight, opacity: startOpacity },
        { height: endHeight, opacity: endOpacity },
      ],
      {
        duration,
        easing,
      }
    )

    containerAnimation.onfinish = () => {
      currentAccordionContainer.style.height = isExpanding ? 'auto' : '0px'
      currentAccordionContainer.style.opacity = endOpacity
      if (!isExpanding) {
        currentAccordionContainer.classList.add('accordion__container--hidden')
      } else {
        currentAccordionContainer.classList.remove(
          'accordion__container--hidden'
        )
      }
    }

    if (!isExpanding) {
      currentAccordionContainer.classList.add('accordion__container--hidden')
    } else {
      currentAccordionContainer.classList.remove('accordion__container--hidden')
    }
  }
}
